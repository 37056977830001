<template>
  <div>
    <div style="margin: 20px 0 0 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="role-main">
      <div class="top">
        <div>
          <Button type="primary" @click="add" style="margin-right: 10px;">添加分类</Button>
          <!-- <Button type="primary" @click="toCategoryVerify" style="margin-right: 10px;">分类审核</Button>
          <Button type="info" @click="toAiGenerateList">AI生成考点</Button> -->
        </div>
        <div>
          <Input v-model="keyword" :placeholder="$t('common_search_keyword')" clearable search :enter-button="true"
            @on-enter="changeKeyword" @on-search="changeKeyword" style="width: 200px" />
        </div>
      </div>
      <div class="search">
        <div class="search-item">
          <div class="name">
            类型:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="typeId == index ? 'active' : ''" v-for="(item, index) in typeObj" :key="index + 'type'"
                @click="changeSearch({ id: index }, 'typeId')">{{ item }}</div>
            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            一级分类:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList"
                :key="item.id + 'category'" @click="changeSearch(item, 'curCategoryId')">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="search-item" v-if="secondCategoryList.length > 1">
          <div class="name">
            二级分类:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList"
                :key="item.id + 'secondCategory'" @click="changeSearch(item, 'cursecondCategoryId')">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="name" style="width: 116px;">
            只显示三级题库:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="show_third_only == item.id ? 'active' : ''" v-for="item in showThirdOnlyData"
                :key="item.id" @click="changeSearch(item, 'show_third_only')">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <Table border :columns="columns" :loading="loading" :data="data"></Table>
      <div class="page">
        <Page :total="total" @on-change="changePage" :current="page" @on-page-size-change="changePageSize" show-total
          show-sizer show-elevator></Page>
      </div>
      <DeleModal :status="deleModalStatus" :txt="$t('dataset_category_dele_tip')" :loading="modalLoading"
        @cancel="cancelDele" @confirm="confirmDele"></DeleModal>

      <!-- <Modal v-model="modalStatus" :title="modalTit" @on-cancel="cancel">
        <Form :model="formItem" ref="formCustom" :label-width="80" :rules="ruleValidate">
          <FormItem :label="$t('dataset_category_table_name')" prop="name">
            <el-input v-model="formItem.name" maxlength="20" size="small"
              :placeholder="$t('dataset_category_add_name_tip')"></el-input>
          </FormItem>
          <FormItem label="状态" prop="status">
            <Select v-model="formItem.status" style="width:200px">
              <Option v-for="item in statusList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="类型" prop="type">
            <RadioGroup v-model="formItem.type" @on-change="typeChange()">
              <Radio :label="index" v-for="(item, index) in typeObj" :key="index">{{ item }}</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="一级分类">
            <Select v-model="formItem.first_parent_id" @on-change="cate1Change()">
              <Option v-for="item in category1List" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="二级分类">
            <Select v-model="formItem.second_parent_id" @on-change="getMapList()">
              <Option v-for="item in category2List" :disabled="item.id == formItem.id" :value="item.id" :key="item.id">{{
                item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="排序">
            <Input v-model="formItem.sort" placeholder="请输入"></Input>
          </FormItem>
          <FormItem :label="$t('dataset_category_table_desc')">
            <Input v-model="formItem.desc" :placeholder="$t('dataset_category_add_desc_tip')"></Input>
          </FormItem>
          <FormItem label="等级" prop="lesson_level" v-if="curData.type == 4 || formItem.type == 4">
            <Select v-model="formItem.lesson_level" placeholder="请选择等级">
              <Option v-for="(item, index) in lessonLevelsObj" :value="index" :key="index">{{ item }}</Option>
            </Select>
          </FormItem>
          <FormItem label="课时数" prop="lesson_count" v-if="curData.type == 4 || formItem.type == 4">
            <Input v-model="formItem.lesson_count" placeholder="请输入课时数"></Input>
          </FormItem>
          <FormItem label="课程描述" prop="content" v-if="curData.type == 4 || formItem.type == 4">
            <Input v-model="formItem.content" type="textarea" placeholder="请输入课程描述"></Input>
          </FormItem>

          <FormItem label="图谱ID" prop="mapId">
            <Select v-model="formItem.mapId" filterable :remote-method="remoteMethod" :loading="mapLoading">
              <Option v-for="item in mapList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="自动开通" prop="autoOpen">
            <RadioGroup v-model="formItem.autoOpen">
              <Radio :label="index" v-for="(item, index) in autoOpenObj" :key="index">{{ item }}</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="免费刷题" prop="isfree">
            <RadioGroup v-model="formItem.isfree">
              <Radio :label="index" v-for="(item, index) in isfreeObj" :key="index">{{ item }}</Radio>
            </RadioGroup>
          </FormItem>
        </Form>
        <div slot="footer">
          <Button @click="cancel">{{ $t('dataset_cancel') }}</Button>
          <Button type="primary" @click="ok" :loading="addBtnLoading" v-if="type != 'check'">{{ $t('dataset_confirm')
          }}</Button>
        </div>
      </Modal> -->
      <TrainSeting :status="trainSetting" @close="closeTrainSetting" @confirm="confirmBrushConfig"></TrainSeting>

      <el-drawer
        :title="modalTit"
        :visible.sync="drawerShow"
        direction="rtl"
        :size="800"
        @close="drawerClose()">
        <div slot="title" class="drawer-title">
          <div>{{ modalTit }}</div>
          <div class="drawer-btn">
            <el-button size="small" @click="cancel">取消</el-button>
            <el-button size="small" @click="ok" type="primary" :loading="addBtnLoading">确定</el-button>
          </div>
        </div>
        <Form :model="formItem" ref="formCustom" :label-width="80" :rules="ruleValidate" style="margin-top: 20px;padding: 20px;">
          <FormItem :label="$t('dataset_category_table_name')" prop="name">
            <el-input v-model="formItem.name" maxlength="40" size="small"
              :placeholder="$t('dataset_category_add_name_tip')"></el-input>
          </FormItem>
          <FormItem label="状态" prop="status">
            <Select v-model="formItem.status" style="width:200px">
              <Option v-for="item in statusList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="类型" prop="type">
            <RadioGroup v-model="formItem.type" @on-change="typeChange()">
              <Radio :label="index" v-for="(item, index) in typeObj" :key="index">{{ item }}</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="一级分类">
            <Select v-model="formItem.first_parent_id" @on-change="cate1Change()">
              <Option v-for="item in category1List" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="二级分类">
            <Select v-model="formItem.second_parent_id" @on-change="getMapList()">
              <Option v-for="item in category2List" :disabled="item.id == formItem.id" :value="item.id" :key="item.id">{{
                item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="排序">
            <Input v-model="formItem.sort" placeholder="请输入"></Input>
          </FormItem>
          <FormItem :label="$t('dataset_category_table_desc')">
            <Input v-model="formItem.desc" :placeholder="$t('dataset_category_add_desc_tip')"></Input>
          </FormItem>
          <FormItem label="等级" prop="lesson_level" v-if="curData.type == 4 || formItem.type == 4">
            <Select v-model="formItem.lesson_level" placeholder="请选择等级">
              <Option v-for="(item, index) in lessonLevelsObj" :value="index" :key="index">{{ item }}</Option>
            </Select>
          </FormItem>
          <FormItem label="课时数" prop="lesson_count" v-if="curData.type == 4 || formItem.type == 4">
            <Input v-model="formItem.lesson_count" placeholder="请输入课时数"></Input>
          </FormItem>
          <FormItem label="周课数" v-if="curData.type == 4 || formItem.type == 4">
            <Input v-model="formItem.lesson_week_count" placeholder="请输入课时数"></Input>
          </FormItem>
          <FormItem label="课程描述" prop="content" v-if="curData.type == 4 || formItem.type == 4">
            <Input v-model="formItem.content" type="textarea" placeholder="请输入课程描述"></Input>
          </FormItem>

          <FormItem label="图谱ID" prop="mapId">
            <!-- <Select v-model="formItem.mapId" filterable :remote-method="remoteMethod">
              <Option v-for="item in mapList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select> -->
            <el-select
              v-model="formItem.mapId"
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键词"
              size="small"
              :remote-method="remoteMethod"
              style="width: 100%;">
              <el-option
                v-for="item in mapList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </FormItem>
          <FormItem label="自动开通" prop="autoOpen">
            <RadioGroup v-model="formItem.autoOpen">
              <Radio :label="index" v-for="(item, index) in autoOpenObj" :key="index">{{ item }}</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="免费刷题" prop="isfree">
            <RadioGroup v-model="formItem.isfree">
              <Radio :label="index" v-for="(item, index) in isfreeObj" :key="index">{{ item }}</Radio>
            </RadioGroup>
          </FormItem>
        </Form>
      </el-drawer>

    </div>
  </div>
</template>

<script>
import DeleModal from '../../components/deleteModal.vue';
import util from '@/utils/tools.js';
import TrainSeting from '../../components/trainSetting'
export default {
  name: "role",
  data() {
    // const validateMapId = (rule, value, callback) => {
    //   console.log(this.formItem.type,'this.formItem.typethis.formItem.type')
    //   if (!value && this.formItem.type == 1) {
    //     return callback(new Error('请输入教材ID'));
    //   }
    //   callback();
    // };

    const validateIssfrees = (rule, value, callback) => {
      if (!value && this.formItem.type == 1) {
        return callback(new Error('请选择是否免费'));
      }
      callback();
    };
    const validateIsAutoOpen = (rule, value, callback) => {
      if (!value && this.formItem.type == 1) {
        return callback(new Error('请选择是否自动开通'));
      }
      callback();
    }


    return {
      drawerShow:false,
      columns: [
        {
          title: this.$t('dataset_category_table_index'),
          key: 'id',
          width:"80"
        },
        {
          title: this.$t('dataset_category_table_name'),
          key: 'name',
          minWidth:200,
          render: (h, params) => {
            return h('router-link', {
              props: {
                to: {
                  name: 'category-detail',
                  params: {
                    id: params.row.id,
                    tab: 'list'
                  }
                }
              },
              style: {
                color: '#2db7f5',
              }
            }, params.row.name);
          }

        },
        {
          title: this.$t('dataset_category_table_data_count'),
          key: 'data_count',
          minWidth:80
        },
        {
          title: '状态',
          key: 'desc',
          minWidth:80,
          render: (h, para) => {
            return h('span', this.statusObj[para.row.status]);
          }
        },
        {
          title: '类型',
          key: 'type',
          minWidth:80,
          render: (h, para) => {
            return h('span', this.typeObj[para.row.type]);
          }
        },
        {
          title: '关联图谱',
          key: 'type',
          minWidth:100,
          render: (h, para) => {
            return h('span', para.row.map ? para.row.map['name'] : '');
          }
        },
        {
          title: '自动开通',
          key: 'type',
          minWidth:100,
          render: (h, para) => {
            return h('span', this.autoOpenObj[para.row.auto_open]);
          }
        },
        {
          title: '免费刷题',
          key: 'type',
          minWidth:100,
          render: (h, para) => {
            return h('span', this.isfreeObj[para.row.is_free]);
          }
        },
        {
          title: '排序',
          key: 'sort',
          minWidth:80,
        },
        // {
        //   title: this.$t('dataset_category_table_desc'),
        //   key: 'desc',
        // },
        {
          title: this.$t('dataset_data_table_created_at'),
          key: 'created_at',
          minWidth:150,
          render: (h, para) => {
            return h('span', util.timeFormatter(
              new Date(+para.row.created_at * 1000), 'MM-dd hh:mm'
            )
            );
          }
        },
        {
          title: this.$t('dataset_data_table_updated_at'),
          key: 'updated_at',
          minWidth:150,
          render: (h, para) => {
            return h('span', util.timeFormatter(
              new Date(+para.row.updated_at * 1000), 'MM-dd hh:mm'
            )
            );
          }
        },
        {
          title: '考点提取和解析',
          minWidth:150,
          render: (h, para) => {
            return h('div', [
              para.row.generate && h('span', this.generateStatusesObj[para.row.generate.status]),
              !para.row.generate && h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.generate(para.row);
                  }
                }
              }, '生成'),

            ]);
          }
        },
        {
          title: this.$t('dataset_category_table_op'),
          width: 200,
          fixed:"right",
          render: (h, params) => {
            return h('div', [
              // h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small'
              //   },
              //   style: {
              //     marginRight: '5px'
              //   },
              //   on: {
              //     click: () => {
              //       // this.edit(params.row)
              //       this.modalTit = '添加分类';
              //       this.type = '';
              //       this.formItem = {
              //         id:'',
              //         name:'',
              //         desc:'',
              //         statusId: '1',
              //         sort: '',
              //         lesson_level:'',
              //         lesson_count:'',
              //         content:'',
              //       };
              //       this.formItem.type = params.row.type;
              //       this.formItem.parentId = params.row.id;
              //       this.formItem.parentName = params.row.name;
              //       this.modalStatus = true;
              //       this.curData = params.row;
              //     }
              //   }
              // }, '添加分类'),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.edit(params.row)
                  }
                }
              }, this.$t('system_edit')),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    // this.edit(params.row)
                    this.$router.push({
                      path: '/trainingcamp/mygroup-create',
                      query: {
                        id: params.row.id,
                      }
                    })
                  }
                }
              }, '更新数据'),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.delete(params.row);
                  }
                }
              }, this.$t('system_dele')),
            ]);
          }
        }
      ],
      data: [],
      loading: false,
      deleteComfirmModal: true,
      deleModalStatus: false,
      modalLoading: false,
      curDeleData: {},
      page: 1,
      pageSize: 10,
      total: 0,
      modalStatus: false,
      formItem: {
        id: '',
        name: '',
        desc: '',
        statusId: '1',
        sort: '',
        parentName: '',
        parentId: '',
        site_id: 0,
        user_id: 0,
        isfree: '',
        autoOpen: '',
        mapId: '',
        type: '',
        lesson_level: '',
        lesson_count: '',
        content: '',
        first_parent_id: "0",
        second_parent_id: "",
        status: "",
        lesson_week_count:'',
      },
      ruleValidate: {
        name: [
          { required: true, message: this.$t('dataset_category_add_name_tip'), trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'blur' }
        ],
        // desc: [
        //   { required: false, message: this.$t('dataset_category_add_desc_tip'), trigger: 'blur' },
        // ],
        type: [
          { required: true, message: '请选择类型', trigger: 'blur' },
        ],
        lesson_level: [
          { required: true, message: '请输入等级', trigger: 'blur' },
        ],
        lesson_count: [
          { required: true, message: '请输入课时数', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请输入课程描述', trigger: 'blur' },
        ],
        mapId: [
          { message: this.$t('dataset_category_add_desc_tip'), trigger: 'blur' },
        ],
        isfree: [
          { message: '请选择是否免费', validator: validateIssfrees, trigger: 'blur' },
        ],
        autoOpen: [
          { message: '请选择是否自动开通', validator: validateIsAutoOpen, trigger: 'blur' },
        ],
      },
      addBtnLoading: false,
      type: '',
      modalTit: '',
      curData: {},
      trainSetting: false,
      categoryList: [],
      curCategoryId: '-1',
      secondCategoryList: [],
      cursecondCategoryId: '-1',
      statusObj: {},
      statusList: [],
      typeId: '0',
      typeObj: {},
      isfreeObj: {},
      autoOpenObj: {},
      keyword: '',
      generateStatusesObj: {},
      mapList: [],
      category1List: [],
      category2List: [],
      lessonLevelsObj: {},
      originFirstParentId: "",
      originSecondParentId: "",
      originType: "",
      mapLoading: false,
      // 只显示三级题库分类数据
      showThirdOnlyData:[
        {
          id:"1",
          name:"是"
        },
        {
          id:"0",
          name:"否"
        }
      ],
      // 是否只显示三级题库
      show_third_only:"1"
    }
  },
  components: {
    DeleModal,
    TrainSeting
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取图谱id
    getMapList() {
      const params = {
        user_id: "-1",
        type: this.formItem.type,
        site_id: "-1",
        pageSize: 30,
        category_id: "-1"
      }
      if (this.formItem.first_parent_id && this.formItem.first_parent_id != "0") {
        params.category_id = this.formItem.first_parent_id;
      }
      if (this.formItem.second_parent_id && this.formItem.second_parent_id != "0") {
        params.category_id = this.formItem.second_parent_id;
      }
      this.api.dataset.mapList(params).then((res) => {
        this.mapList = res.list || [];
      });
    },
    getCategory1Data() {
      const params = {
        type: this.formItem.type,
        site_id: "-1",
      }
      this.api.dataset.categoryForm(params).then((res) => {
        this.category1List = res.categoryFirstList;
        this.category1List.unshift({
          id: "0",
          name: "无"
        });
      });
    },
    getCategory2Data() {
      if (!this.formItem.first_parent_id || this.formItem.first_parent_id === "0") return;
      const params = {
        category_id: this.formItem.first_parent_id,
        site_id: "-1",
      }
      this.api.dataset.categoryForm(params).then((res) => {
        this.category2List = res.categorySecondList;
        this.category2List.unshift({
          id: "0",
          name: "无"
        });
      });
    },
    typeChange() {
      this.getMapList();
      this.getCategory1Data();
      this.formItem.second_parent_id = "";
      this.category2List = [];
      if (this.originType == this.formItem.type) {
        this.formItem.first_parent_id = this.originFirstParentId;
        this.formItem.second_parent_id = this.originSecondParentId;
        this.getCategory2Data();
      }
    },
    cate1Change() {
      this.formItem.second_parent_id = "";
      this.category2List = [];
      this.getCategory2Data();
      this.getMapList();
    },
    remoteMethod(query) {
      if (query) {
        const params = {
          user_id: "-1",
          type: this.formItem.type,
          site_id: "-1",
          pageSize: 30,
          keyword: query
        }
        if (this.formItem.first_parent_id && this.formItem.first_parent_id != "0") {
          params.category_id = this.formItem.first_parent_id;
        }
        if (this.formItem.second_parent_id && this.formItem.second_parent_id != "0") {
          params.category_id = this.formItem.second_parent_id;
        }
        this.api.dataset.mapList(params).then((res) => {
          this.mapList = res.list || [];
        });
      }
    },
    drawerClose(){
      this.mapList=[];
    },
    changeKeyword() {
      this.page = 1;
      this.getList();
    },
    toCategoryVerify() {
      this.$router.push({
        path: '/dataset/category-verify',
      })
    },
    toAiGenerateList() {
      this.$router.push({
        path: '/dataset/map-aigenerate-list',
        query: {

        }
      })
    },

    changeSearch(data, name) {
      if (name == 'typeId') {
        this.curCategoryId = '-1';
        this.cursecondCategoryId = '-1';
      } else if (name == 'curCategoryId') {
        this.cursecondCategoryId = '-1';
      }
      this[name] = data.id;
      this.page = 1;
      this.getList();
    },
    add() {
      this.modalTit = this.$t('dataset_category_add');
      this.type = '';
      this.formItem = {
        id: '',
        name: '',
        desc: '',
        statusId: '1',
        sort: '',
        type: '0',
        lesson_level: '',
        lesson_count: '',
        content: '',
        status: "1",
        autoOpen: '0',
        isfree: '0',
        mapId:"",
        lesson_week_count:'',
      };
      this.formItem.parentId = 0;
      this.getCategory1Data();
      this.getMapList();
      this.modalStatus = true;
      this.drawerShow=true;
    },
    check(data) {
      this.modalTit = this.$t('dataset_category_check');
      this.type = 'check';
      this.formItem = {
        id: data.id,
        name: data.name,
        data_count: data.data_count,
        desc: data.desc
      };
      this.modalStatus = true;
    },
    edit(data) {
      this.api.dataset.categoryDetail({ id: data.id }).then(res => {
        this.formItem = {...res.info} || {};
        this.formItem.first_parent_id = res.first_parent_id || "0";
        this.formItem.second_parent_id = res.second_parent_id || "0";
        this.formItem.autoOpen = res.info.auto_open || "";
        this.formItem.isfree = res.info.is_free || "";
        this.$set(this.formItem,"mapId",res.info.map_id);
        // 保留状态
        this.originFirstParentId = res.first_parent_id || "";
        this.originSecondParentId = res.second_parent_id || "";
        this.originType = res.info.type || "";
        this.getMapList();
        this.getCategory1Data();
        this.getCategory2Data();
      });
      this.modalTit = this.$t('dataset_category_edit');
      this.type = 'edit';
      // this.formItem = {
      //   site_id:-1,
      //   user_id:-1,
      //   id:data.id,
      //   name:data.name,
      //   desc:data.desc,
      //   statusId: data.status,
      //   parentName: data.parent ? data.parent.name : '',
      //   parentId: data.parent_id,
      //   sort: data.sort,
      //   type:data.type,
      //   mapId: data.map_id,
      //   isfree: data.is_free,
      //   autoOpen: data.auto_open,
      //   lesson_level:data.lesson_level,
      //   lesson_count:data.lesson_count,
      //   content:data.content,
      // };
      this.drawerShow=true;
      this.modalStatus = true;
    },
    getList() {
      this.loading = true;
      let params = {
        site_id: -1,
        user_id: -1,
        page: this.page,
        pageSize: this.pageSize,
        category_id: this.curCategoryId,
        second_category_id: this.cursecondCategoryId,
        type: this.typeId,
        keyword: this.keyword,
        show_third_only:this.show_third_only
      };
      this.api.dataset.categoryList(params).then((res) => {
        this.loading = false;
        this.lessonLevelsObj = res.lesson_levels;
        if (!res.list.length && this.page > 1) {
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
        this.categoryList = [
          {
            name: '全部',
            id: '-1'
          },
          ...res.categoryFirstList
        ];
        this.secondCategoryList = [
          {
            name: '全部',
            id: '-1'
          },
          ...res.categorySecondList
        ];
        this.statusObj = res.statuses;
        this.statusList = [];
        for (let name in res.statuses) {
          this.statusList.push({
            name: res.statuses[name],
            id: name
          })
        }
        this.typeObj = res.types;

        this.isfreeObj = res.isfrees;
        this.autoOpenObj = res.autoOpens;
        this.generateStatusesObj = res.generate_statuses;
        this.levelList = [];
        for (let name in res.lesson_levels) {
          this.levelList.push({
            name: res.lesson_levels[name],
            id: name
          })
        }

      }).catch(() => {
        this.loading = false;
      })
    },
    delete(data) {
      this.curDeleData = data;
      this.deleModalStatus = true;
    },
    cancelDele() {
      this.deleModalStatus = false;
    },
    confirmDele() {
      let data = {
        site_id: -1,
        user_id: -1,
        id: this.curDeleData.id,
      }
      this.modalLoading = true;
      this.api.dataset.categoryDelete(data).then(() => {
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch(() => {
        this.modalLoading = false;
      });
    },
    changePage(page) {
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    ok() {
      this.$refs.formCustom.validate((valid) => {
        if (valid) {
          let data = {
            site_id: this.formItem.site_id,
            user_id: this.formItem.user_id,
            id: this.formItem.id,
            name: this.formItem.name,
            desc: this.formItem.desc,
            status: this.formItem.status,
            sort: this.formItem.sort,
            type: this.formItem.type || this.curData.type,
            map_id: this.formItem.mapId,
            is_free: this.formItem.isfree,
            auto_open: this.formItem.autoOpen,
            lesson_level: this.formItem.lesson_level,
            lesson_count: this.formItem.lesson_count,
            lesson_week_count:this.formItem.lesson_week_count,
            content: this.formItem.content,
            parent_id: "0",
          };

          if (this.formItem.first_parent_id != "0" && this.formItem.first_parent_id) {
            data.parent_id = this.formItem.first_parent_id;
          }

          if (this.formItem.second_parent_id != "0" && this.formItem.second_parent_id) {
            data.parent_id = this.formItem.second_parent_id;
          }
          this.addBtnLoading = true;
          if (this.formItem.id) {
            this.api.dataset.categoryUpdate(data).then(() => {
              this.addBtnLoading = false;
              this.drawerShow=false;
              this.modalStatus = false;
              this.getList();
            }).catch(() => {
              this.addBtnLoading = false;
            })
          } else {
            this.api.dataset.categoryCreate(data).then(() => {
              this.addBtnLoading = false;
              this.drawerShow=false;
              this.modalStatus = false;
              this.page = 1;
              this.getList();
            }).catch(() => {
              this.addBtnLoading = false;
            })
          }
        }
      })
    },
    cancel() {
      this.modalStatus = false;
      this.drawerShow=false;
      this.$refs.formCustom.resetFields();
    },

    confirmBrushConfig(data) { //刷题设置确认
      let params = {
        action: 'userSetting',
        product_id: 2, //活动 ID 每日一练:2
        ...data
      };
      this.api.dataset.trainExecute(params).then(() => {
        this.trainSetting = false;
        this.$router.push({
          path: '/trainingcamp/trainDetail',
          query: {
            id: this.curData.id,
            dataSort: '0',
          }
        })
      }).catch(() => {
        this.trainSetting = false;
      })
    },
    closeTrainSetting() {
      this.trainSetting = false;
    },
    confrimTrainDetail(data, dataSort) { //0顺序1随机4每日一练
      this.$router.push({
        path: '/trainingcamp/trainDetail',
        query: {
          id: this.curData.id,
          dataSort: dataSort,
        }
      })
    },
    generate(data) { //刷题设置确认
      let params = {
        category_id: data.id,
      };
      this.api.dataset.categoryGenerateStart(params).then(() => {
        this.getList();
      }).catch(() => {
      })
    },
  }
}
</script>

<style scoped lang="scss">
.role-main {
  margin: 20px;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.4100);
  border-radius: 4px;

  .search {
    margin-top: 20px;
    margin-bottom: 20px;

    .search-item {
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name {
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }

      .cont {
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values {
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div {
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }

          >div.active {
            background-color: #2d8cf0;
            color: #FFFFFF;
            border-radius: 4px;
          }
        }

        .more {
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;

          .more-icon {
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }

  .top {
    text-align: left;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .page {
    margin-top: 20px;
    text-align: right;
  }
}

.brush-question {
  .tit {
    font-size: 16px;
    font-weight: bold;
  }

  .list {
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    >span {
      margin-right: 10px;
      padding: 5px 20px;
      display: inline-block;
      background-color: #e8eaec;
      border-radius: 4px;
      cursor: pointer;
    }

    >span.active {
      border: 1px solid #2d8cf0;
      background-color: #2d8cf0;
      color: #FFFFFF;
    }
  }
}
::v-deep .el-drawer {
  .el-drawer__header {
    margin-bottom: 0;
  }

  .drawer-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: #333333;
    font-weight: bold;

    .drawer-btn {
      margin-right: 20px;
    }
  }
}
</style>
